import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Tabs from '../../components/product/Tabs';
import {axiosPrivate} from "../../lib/api";
import ProductSkeleton from "../../components/product/ProductSkeleton";
import { notify } from '../../components/UI/Toast';

const Product = () => {
    const {productId} = useParams();  // Get the productId from the URL
    const [product, setProduct] = useState(null);
    const [bulletPoints, setBulletPoints] = useState([]);
    const [imagesByVariant, setImagesByVariant] = useState({});
    const [variants, setVariants] = useState([]);
    const [salesTrafficList, setSalesTrafficList] = useState([]);
    const [keywordsData, setKeywordsData] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await axiosPrivate.get(`products/api/products/${productId}`);
                const data = response.data;
                const { product, bullet_points, images_by_variant, variants, sales_traffic_list, chart_data, keywords_data } = data;
                setProduct(product);
                setBulletPoints(bullet_points);
                setImagesByVariant(images_by_variant);
                setVariants(variants);
                setSalesTrafficList(sales_traffic_list);
                setChartData(chart_data);
                setKeywordsData(keywords_data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching product details:', error);
                setLoading(false);
            }
        };
        fetchProductDetails();
    }, [productId]);  // Rerun the effect when productId changes
    const handleSync = async () => {
        try {
            const response = await axiosPrivate.post(`products/api/products/${productId}`, {
                'step': "PRODUCT_UPDATE_WITH_AMAZON"
            });
            const data = await response.data;
            const { product, bullet_points, images_by_variant, variants, sales_traffic_list, chart_data } = data;
            setProduct(product);
            setBulletPoints(bullet_points);
            setImagesByVariant(images_by_variant);
            setVariants(variants);
            setSalesTrafficList(sales_traffic_list);
            setChartData(chart_data);
            setLoading(false);
            notify.success("Product Synced With Amazon")
        } catch (error) {
            console.error('Error syncing product:', error);
        }
    };
    if (loading) return <ProductSkeleton />

    if (!product) {
        return <div>No product found.</div>;
    }

    return (
        <div className="container mx-auto p-4">
            <div className="bg-white p-8 rounded-lg shadow-lg mb-6">
                <div className="flex flex-col md:flex-row items-start md:items-center gap-6">
                    <h1 className="text-3xl font-bold text-gray-900">{product.attributes.item_name}</h1>
                    <a target="_blank" rel="noopener noreferrer" href={`https://www.amazon.com/d/${product.asin1}`}>
                        <img src="https://cdn0.iconfinder.com/data/icons/most-usable-logos/120/Amazon-512.png"
                             height="100" width="100" alt="Amazon"/>
                    </a>
                </div>
                <p className="text-gray-700 mt-4">{product.item_description}</p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
                    <div className="p-6 border border-gray-200 rounded-lg bg-gray-50 shadow-sm">
                        <span className="text-sm font-medium text-gray-500">Price</span>
                        <span className="block text-xl font-bold text-gray-900 mt-1">${product.price}</span>
                    </div>
                    <div className="p-6 border border-gray-200 rounded-lg bg-gray-50 shadow-sm">
                        <span className="text-sm font-medium text-gray-500">ASIN</span>
                        <span className="block text-xl font-bold text-gray-900 mt-1">{product.asin1}</span>
                    </div>
                    <div className="p-6 border border-gray-200 rounded-lg bg-gray-50 shadow-sm">
                        <span className="text-sm font-medium text-gray-500">SKU</span>
                        <span className="block text-xl font-bold text-gray-900 mt-1">{product.seller_sku}</span>
                    </div>
                </div>
                <h3 className="font-bold text-lg text-gray-900 mt-8">About this item:</h3>
                <ul className="list-disc pl-6 mt-4 text-gray-700">
                    {bulletPoints.map((point, index) => (
                        <li key={index} className="mb-2">{point.value}</li>
                    ))}
                </ul>
            </div>

            <Tabs
                imagesByVariant={imagesByVariant}
                variants={variants}
                salesTrafficList={salesTrafficList}
                chartData={chartData}
                productId={product.id}
                keywordsData={keywordsData}
                setKeywordsData={setKeywordsData}
            />
            <SyncComponent handleSync={handleSync}/>
        </div>
    );
};

export default Product;

const SyncComponent = ({handleSync}) => {
    return (
        <div className="container mx-auto my-6">
            <div className="bg-white p-8 rounded-lg shadow-lg flex items-center justify-between">
                {/* Message on the left side */}
                <p className="text-gray-700 text-left w-3/4">
                    Sometimes the data shown here may not reflect the latest changes you've made on Amazon.
                    If you've recently updated your product details, you can sync it with the latest Amazon data by
                    clicking
                    the button.
                </p>

                {/* Button on the right side */}
                <button
                    onClick={handleSync}
                    className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg transition duration-300 ease-in-out w-1/4"
                >
                    <i className="fas fa-sync-alt mr-2"></i> Sync Now
                </button>
            </div>
        </div>
    );
};