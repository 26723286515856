import React, {useState} from 'react';
import ImagesTab from "./ImagesTab";
import VariantsTab from "./VariantsTab";
import Recommendation from "./Recommendation";
import ChartTab from "./ChartTab";
import Keywords from "./Keywords";
import ImageRecommendation from './ImageRecommendation'

const Tabs = ({imagesByVariant, variants, productId, chartData, keywordsData, setKeywordsData}) => {
    const [activeTab, setActiveTab] = useState('images');

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };
    let display = (() => {
        switch (activeTab) {
            case "variants":
                return <VariantsTab variants={variants}/>
            case "chart":
                return <ChartTab chartData={chartData}/>
            case "recommendation":
                return <Recommendation productId={productId}/>
            case "imageRecommendation":
                return <ImageRecommendation productId={productId}/>
            case "keywords":
                return <Keywords keywordsData={keywordsData} productId={productId} setKeywordsData={setKeywordsData}/>
            default:
                return <ImagesTab imagesByVariant={imagesByVariant}/>

        }
    });
    return (
        <div className="bg-white p-4 rounded shadow">
            <div className="border-b border-gray-200 mb-4 flex">
                <div onClick={() => handleTabClick('images')}
                     className={`inline-block p-2 mr-4 cursor-pointer ${activeTab === 'images' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-blue-600'}`}>
                    Images
                </div>
                {variants.length > 0 && (
                    <div onClick={() => handleTabClick('variants')}
                         className={`inline-block p-2 mr-4 cursor-pointer ${activeTab === 'variants' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-blue-600'}`}>
                        Variants
                    </div>

                )}
                <div onClick={() => handleTabClick('chart')}
                     className={`inline-block p-2 mr-4 cursor-pointer ${activeTab === 'chart' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-blue-600'}`}>
                    Chart
                </div>
                <div onClick={() => handleTabClick('keywords')}
                     className={`inline-block p-2 mr-4 cursor-pointer ${activeTab === 'keywords' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-blue-600'}`}>
                    Keywords
                </div>
                <div onClick={() => handleTabClick('recommendation')}
                     className={`inline-block p-2 mr-4 cursor-pointer ${activeTab === 'recommendation' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-blue-600'}`}>
                    Recommendation
                </div>
                <div onClick={() => handleTabClick('imageRecommendation')}
                     className={`inline-block p-2 mr-4 cursor-pointer ${activeTab === 'imageRecommendation' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-blue-600'}`}>
                    Image Recommendation
                </div>
            </div>
            <div id="tabContent">
                {display()}
            </div>
        </div>
    );
};

export default Tabs;
