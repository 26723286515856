import React, { useState } from 'react';
import { axiosPrivate } from "../../lib/api";
import KeywordsDataTable from "./KeywordsDataTable";
import { FaSpinner, FaTimes } from 'react-icons/fa';

const KeywordsTab = ({ keywordsData, productId, setKeywordsData }) => {
    const [newKeyword, setNewKeyword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const addKeyword = async () => {
        setError('');
        if (!newKeyword.trim()) return setError('Keyword cannot be empty.');
        setLoading(true);
        try {
            const response = await axiosPrivate.post(`products/api/products/${productId}`, {
                step: "ADD_PRODUCT_KEYWORDS",
                product_id: productId,
                keywords: [newKeyword.trim()],
            });
            setKeywordsData(response.data.keyword_data);
            setNewKeyword('');
        } catch (error) {
            console.error('Error adding keyword:', error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="p-12 bg-gray-50">
            {/* Input and Add Button Section */}
            <div className="mb-6 flex flex-col sm:flex-row items-center justify-start gap-4">
                {/* Keyword Input */}
                <div className="relative w-full sm:w-1/2">
                    <input
                        type="text"
                        value={newKeyword}
                        onChange={(e) => {
                            setNewKeyword(e.target.value);
                            if (error) setError('');
                        }}
                        placeholder="Add a new keyword"
                        className="w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    {newKeyword && (
                        <FaTimes
                            className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500 hover:text-gray-700"
                            onClick={() => setNewKeyword('')}
                        />
                    )}
                </div>

                {/* Add Keyword Button */}
                <button
                    onClick={addKeyword}
                    disabled={loading}
                    className="px-6 py-3 bg-green-500 text-white font-medium rounded-md shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 disabled:bg-gray-300 disabled:cursor-not-allowed"
                >
                    {loading ? (
                        <span className="flex items-center justify-center space-x-2">
                    <FaSpinner className="animate-spin h-5 w-5"/>
                    <span>Loading...</span>
                </span>
                    ) : (
                        'Add Keyword'
                    )}
                </button>
                {error && (
                <div className="mb-4 text-red-500 text-sm pt-2">
                    {error}
                </div>
            )}
            </div>

            {/* Error Message */}


            {/* Keywords Data Table */}
            <KeywordsDataTable
                data={keywordsData}
                productId={productId}
                setKeywordsData={setKeywordsData}
            />
        </div>
    );
};

export default KeywordsTab;
